.whatsapp-button {
    background: #25D366 !important;
    border-radius: .4rem !important;
    width: 40% !important;
}

.main-div {
    justify-content: center !important;
    display: flex !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-size: cover !important;
    height: 100% !important;
}

.second-div {
    background-color: white !important;
}

.container{
    min-height: 96vh;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.container2{
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.avatar {
    margin-top: 30px;
    width: 200px;
    height: 200px;
}

.grid {
    padding-top: 64px;
    align-items: center;
    justify-content: center;
}

.active {
    border-radius: 8rem !important;
    color: white !important;
    font-weight: bold !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
    right: 0;
    background: #1fad69 !important;
    margin-top: 8px !important;
    margin-right: 8px !important;
    position: fixed !important;
    z-index: 1 !important;
    font-size: 12px !important;
}
