.card {
    margin: 0 auto;
    padding: 10px;
}

.titles {
    font-weight: bold;
}

.row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
}

.value {
    font-size: 20px;
    padding-left: 4px;
    padding-right: 4px;
}

.buttonCounter {
    appearance: none;
    outline: none;
    color: rgba(87, 164, 150) !important;
}

.button {
    width: 100%;
    height: 100%;
    font-weight: bold;
    background-color: #57a496 !important;
}

.container {
    padding-right: 16px !important;
    margin-bottom: 12px !important;
}

.amount {
    padding-top: 4px !important;
    font-weight: bold !important;
}

.grid {
    padding-left: 8px;
}
