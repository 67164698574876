.container {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 1px;
    padding-top: 8px;
    justify-content: space-around;
    align-items: center;
}

.icon {
    font-size: 40px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.grid {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
}

.grid1 {
    position: sticky;
    top: 135px;
    width: 25% !important;
    z-index: 2;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 5px 5px #D1D1D1 !important;
}

.grid2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}
