.card {
    margin: 0 auto;
    padding: 10px;
}

.titles {
    font-weight: bold;
}

.row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
}

.value {
    font-size: 20px;
    padding-left: 16px;
    padding-right: 16px;
}

.buttonCounter {
    appearance: none;
    outline: none;
    color: rgba(87, 164, 150) !important;
}

.button {
    width: 100%;
    height: 100%;
    font-weight: bold;
    background-color: #57a496 !important;
}
