.container {
    margin-top: 36px;
    margin-bottom: 16px;
    margin-left: 8px;
    margin-right: 8px;
    justify-content: center;
}

.container1 {
    padding: 8px;
}

.button {
    width: 50%;
}

.icon {
    width: 48px;
    height: 48px;
}
