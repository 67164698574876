.button1 {
    border-radius: .4rem;
    width: 50%;
    background-color: #25D366 !important;
    margin-top: 16px !important;
}

.button2{
    width: 40%;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: .4rem;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.button3 {
    border-radius: .4rem;
    width: 50%;
    margin-top: 8px !important;
}

.button4 {
    width: 40%;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    border-radius: .4rem;
}

.grid {
    padding-top: 30px;
    align-items: center;
    justify-content: center;
}

.grid2 {
    align-items: center;
    justify-content: center;
}
