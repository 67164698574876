.container {
    margin-top: 24px;
    justify-content: center;
    align-items: center;
}

.text {
    text-align: center;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 25px !important;
    font-weight: bold !important;
}

.text2 {
    margin-top: 20px !important;
    font-size: 18px !important;
}

.div2 {
    display: flex !important;
    align-items: center !important;
}

.icons {
    width: 20px;
    height: 20px;
}

.font2 {
    color: black;
    padding-left: 8px;
    font-size: 13px;
}
