.container {
    box-shadow: none !important;
    margin: 0 auto;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    justify-content: center;
}

.titles {
    font-weight: bold;
    justify-content: center;
    align-content: center;
    margin-top: 8px !important;
}

.titles1 {
    font-weight: bold;
    margin-top: 16px !important;
}

.titles2 {
    font-weight: bold;
    justify-content: center;
    align-content: center;
    margin-top: 16px !important;
}

.container1 {
    margin-bottom: 8px;
    padding: 16px;
}

.container2 {
    width: 100%;
    min-height: 700px;
}

.grid {
    justify-content: center;
    align-content: center;
    justify-items: center;
}

.grid1 {
    padding: 24px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: .4rem;
    justify-content: center;
    align-content: center;
}

.grid2 {
    padding: 24px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: .4rem;
    margin-left: 8px;
    justify-content: center;
    align-content: center;
}

.grid3 {
    justify-content: center;
    align-content: center;
    justify-items: center;
}

.grid4 {
    justify-content: flex-end;
    align-items: center;
    padding-top: 8px;
}

.primaryButtonColor {
    color: white !important;
    border-radius: 8rem !important;
    padding: 8px !important;
    margin-bottom: 8px !important;
    position: fixed !important;
    bottom: 0 !important;
}