.button {
    color: white !important;
    background: #57A496 !important;
    padding: 8px !important;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 100px;
}
