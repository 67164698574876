.titles {
    font-weight: bold;
    justify-content: center;
    align-content: center;
    margin-top: 8px !important;
}

.titles1 {
    font-weight: bold;
    margin-top: 24px !important;
}

.titles2 {
    font-weight: bold;
    justify-content: center;
    align-content: center;
    margin-top: 8px !important;
    margin-left: 20px !important;
}

.grid {
    margin: 16px !important;
}
