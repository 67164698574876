
.avatar {
    margin-top: 30px;
    width: 200px !important;
    height: 200px !important;
}

.grid {
    padding-top: 32px;
    align-items: center;
    justify-content: center;
}
