.title {
    margin-bottom: 8px;
}

.container {
    margin: 8px !important;
    padding-right: 12px !important;
}

.grid {
    margin: 8px !important;
    padding-right: 12px !important;
    justify-content: space-between !important;
    align-items: center;
}

.rowViewMap {
    position: fixed !important;
    left: 49% !important;
    height: 550px !important;
    width: 40% !important;
    max-height: 550px !important;
    max-width: 590px !important;
    margin-top: 16px !important;
    padding: 8px !important;
}

.rowViewAddresses {
    padding: 8px !important;
}

.verticalViewAddresses {
    position: fixed !important;
    top: 40px !important;
    height: 360px !important;
    width: 100% !important;
    overflow-y: scroll !important;
    padding: 8px !important;
}


.verticalViewMap {
    position: fixed !important;
    top: 400px !important;
    align-items: center !important;
    justify-content: center !important;
    justify-items: center !important;
    height: 40% !important;
    width: 94% !important;
    margin-top: 16px !important;
    padding: 8px !important;
}
