.rowView {
    position: fixed !important;
    overflow-y: scroll !important;
    left: 50% !important;
    height: 500px !important;
    width: 45% !important;
    max-height: 500px !important;
    max-width: 600px !important;
    margin-top: 16px !important;
}

.scrollHidden {
    overflow: hidden !important;
    height: 500px;
}

.verticalView {

}

.button {
    width: 100% ;
    margin-right: 10px !important;
    margin-left: 8px !important;
    border-radius: .4rem !important;
}
