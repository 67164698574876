.container {
    padding-right: 8px;
}

.footer {
    position: inherit;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2.5rem;
}

.buttonCounter {
    color: rgba(87, 164, 150) !important;
}

.center {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}