.button {
    width: 100% ;
    margin-right: 10px !important;
    margin-left: 8px !important;
    border-radius: .4rem !important;
}

.close {
    position: absolute;
    color: red;
    right: 24px;
    top: 8px;
    margin-bottom: 24px;
}

.title {
    margin-bottom: 8px;
}

.container {
    width: 100%;
    min-height: 700px;
}

.box {
    text-align: center;
    padding-bottom: 5px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.price {
    margin-bottom: 8px !important;
}
