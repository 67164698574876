
.button {
    margin-right: 16px !important;
    margin-left: 16px !important;
    margin-bottom: 16px !important;
    border-radius: .4rem !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.grid {
    margin-top: 64px !important;
    bottom: 0 !important;
}