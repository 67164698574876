.container3 {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    align-items: center;
}

.font {
    /* paddingRight: 8, paddingTop: 8 */
    padding-right: 8px;
    padding-top: 8px;
}

.div {
    margin-right: 16px !important;
    margin-left: 16px !important;
}

.box {
    margin-bottom: 50px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}