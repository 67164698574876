.item {
    padding-left: 0 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.itemPhoto {
    margin-right: 10px;
}

.itemText {
    padding-right: 10px !important;
}

.header {
    background: #F0F0F0 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.headerText {
    color: black;
}

.mandatory {
    border-radius: 8rem !important;
    color: white;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
}

.textField {
    width: 100%;
}