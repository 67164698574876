.container {
    margin-bottom: 8px;
}

.container1 {
    box-shadow: none !important;
    margin: 0 auto;
}

.container2 {
    width: 100%;
    min-height: 700px;
}

.container5 {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    justify-content: center;
}

.container6 {
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.container7 {
    padding: 8px;
}

.container8 {
    padding: 16px;
    background: rgb(83, 78, 104, .08);
}

.container9 {
    height: 500px;
    position: relative;
    padding-right: 8px;
    margin-right: 8px;
}

.end {
    border-radius: .4rem;
}