.button {
    border-radius: .4rem;
    padding-bottom: 8px;
    padding-top: 8px;
}

.container {
    padding-top: 0;
    margin-bottom: 24px;
    margin-top: 8px;
}