.photo {
    border-radius: 5px;
}

.card {
    box-shadow: none !important;
    padding-top: 15px;
}

.titles {
    font-weight: bold;
}

.infoSection {
    padding: 8px !important;
}
