.image {
    width: 24px;
    height: 24px;
}

.logo {
    width: 250px;
    height: 120px;
    padding: 8px;
    margin-bottom: 12px;
}

.list {
    margin-top: auto;
}
