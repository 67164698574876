.card {
    margin-right: 16px;
    margin-left: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
}

.titles {
    font-weight: bold;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid gray;
    border-radius: .4rem !important;
}

.value {
    font-size: 20px;
    padding-left: 16px;
    padding-right: 16px;
}

.buttonCounter {
    appearance: none;
    outline: none;
    color: rgba(87, 164, 150) !important;
}

.button {
    width: 100%;
    height: 45px;
    font-weight: bold;
    padding: 16px;
    border-radius: .4rem !important;
}
