.header {
    background: #F0F0F0;
    padding: 8px;
}

.header1 {
    background: #F0F0F0;
}

.item {
    padding-left: 8px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.itemPhoto {
    margin-right: 10px;
}

.itemText {
    padding-right: 10px !important;
}

.button {
    width: 50px;
    height: 30px;
}

.font1 {
    color: black !important;
    font-weight: bold !important;
}

.font2 {
    font-size: 11px !important;
}

.divider {
    width: 90%;
}

.font3 {
    font-size: 25px !important;
    text-align: center !important;
}
