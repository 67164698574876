.item {
    padding-left: 0 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.itemPhoto {
    margin-right: 10px;
}

.itemText {
    padding-right: 10px !important;
}

.header {
    background: #F0F0F0 !important;
}

.headerSection {
    background: #F0F0F0 !important;
    padding-top: 10px;
    padding-bottom: 10px;
}

.headerText {
    color: black;
}

.textField {
    width: 100%;
}

.avatar {
    width: 30px;
    height: 30px;
}