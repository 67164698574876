.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 3 !important;
}

.list {
    height: 100vh;
}

.container {
    min-height: 960px;
}