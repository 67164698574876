@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@200;400;600;700&family=Montserrat:wght@200;400;600&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root{
  height: 100vh;
}

.loginContainer{


}

/*@font-face {
  font-family: 'Medium';
  src: url('/fonts/Poppins-Medium.ttf') format('ttf'),
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}*/
