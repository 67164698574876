.grid1 {
    padding: 8px;
    z-index: 1;
    margin-bottom: 0;
    align-items: center;
}

.grid2 {
    z-index: 1;
    margin-bottom: 0;
    padding: 8px;
    align-items: center;
}

.grid4 {
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
}

.grid3 {
    align-items: center;
    justify-content: flex-start;
    display: flex;
}

.grid5 {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    background-color: white;
    width: 22.5% !important;
    top: 78px;
    position: fixed;
    overflow: auto;
    box-shadow: 1px 5px 5px #D1D1D1 !important;
    padding-bottom: 50px;
    padding-top: 8px;
    padding-left: 8px;
}

.deliveryOff {
    border-radius: 8rem !important;
    color: white !important;
    font-weight: bold !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
    background: #F54335 !important;
}

.deliveryOffBanner {
    border-radius: 8rem !important;
    color: white !important;
    font-weight: bold !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
    background: #F54335 !important;
    margin-top: 8px !important;
    margin-left: 48px !important;
    margin-right: 8px !important;
    position: fixed !important;
    z-index: 1 !important;
    font-size: 16px !important;
}

.container {
    padding-right: 8px;
    align-items: center;
    justify-content: flex-end;
}

.font1 {
    background-color: #F54335;
    margin-left: 8px;
}

.font2 {
    color: black;
    padding-left: 8px;
    font-size: 13px;
    padding-right: 8px;
}

.font3 {
    color: black;
    padding-left: 8px;
    font-size: 12px;
    padding-right: 8px;
}

.avatar {
    z-index: 1 !important;
}

.div1 {
    padding-right: 8px !important;
}

.div2 {
    display: flex !important;
    align-items: center !important;
}

.icons {
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-right: 2px;
}

.shopping-cart {
    padding: 8px !important;
    margin-right: 8px !important;
    margin-left: 8px !important;
}

.shopping {
    margin-left: 8px;
    padding-left: 8px;
    font-size: 40px !important;
}

.icon {
    color: #666666 !important;
}

.search {
    font-size: 40px !important;
}

