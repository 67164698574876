.header {
    background: #F0F0F0 !important;
    height: 45px;
    margin-bottom: 5px;
    padding-top: 8px;
}

.headerText {
    color: black;
}

.boxTextField {
    padding: 10px;
}

.textField {
    width: 100%;
    color: #57A496;
}