.item {
    padding-left: 8px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    align-items: center !important;
    justify-content: center;
    cursor: pointer !important;
}

.font1 {
    color: black !important;
    font-size: 16px !important;
}

.font2 {
    color: gray;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 0.7rem !important;
    line-height: 1.2rem;
    height: 4.4rem;
}

.font3 {
    color: black !important;
    font-weight: bold !important;
    font-size: 16px !important;
}

.font4 {
    color: gray;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 0.7rem !important;
    line-height: 1.2rem;
    height: 2.8rem;
}

.grid1 {
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
}

.grid2 {
    align-items: center;
    align-content: flex-end;
}
